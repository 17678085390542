module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.usdirect.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"chatEnabled":true,"analyticsId":"GTM-KF9GVR","brandTokens":["DTV","DTVB","DTVBP","ATT"],"mapiBrandToken":"DTV","siteName":"usdirect","alternateName":"usdirect.com","siteURL":"https://www.usdirect.com","defaultTitleTemplate":"","defaultPhone":"8333971901","phoneSymbol":".","defaultPromoCode":"6861","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046060","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"usdirect","short_name":"usdirect","start_url":"/","background_color":"#FDD746","theme_color":"#FDD746","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e4fd6637f64bc590460cdf6d567d2646"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"qqr6xvi"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
